import React from 'react'
import aboutimg from '../assets/about back.png'
const Aboutus = () => {
  return (
    <section className='container mt-5'>
        <div className='row align-items-center'>
            <div className='col-lg-6 col-12 px-0'>
                <img src={aboutimg} className="img-fluid"/>
            </div>
            <article className='col-lg-6 col-12 mt-lg-0 mt-4'>
              <h2 className='text-lg-start text-center' id='aboutus'>About us</h2> 
              <p className='mt-4 text-lg-start text-center text-black-50'>
              Magi Stone met à votre disposition le plan de travail en quartz Comapc, Silestone et Dekton, le quartz étant aussi résistant que le granit.
Disponible en de nombreux coloris, antibactérien, facile à nettoyer, le plan de travail en quartz s'adapte à tous les styles de cuisine.
Nous vous offrons une palette de plus de 50 coloris tendance, différentes finitions et épaisseurs afin de s’adapter à vos envies et au style de votre cuisine. A part le quartz, vous pouvez choisir autre matériaux pour votre plan de travail : le granit, la céramique, etc.
              </p>
            </article>
        </div>
    </section>
  )
}

export default Aboutus