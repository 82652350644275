import React,{useState,useEffect} from 'react'
import axios from 'axios'
const FirstSection = () => {  
  const [data,setdata] = useState(
    {
      nom:"",
      email:"",
      telephone:"",
      message:""
    }
  )

  const handlechange = (e)=>{
    setdata(
      prevdata =>{
        return{
          ...prevdata,[e.target.name]:e.target.value
        }
      }
    )
  }
  
  const handlesubmit = async (e)=>{
    e.preventDefault()
    
    if(data.nom === "" || data.nom==null){
      document.querySelector('.validation-nom').classList.remove("d-none")
    }
    else if(data.email === "" || data.email == null){
      document.querySelector('.validation-nom').classList.add("d-none")
      document.querySelector('.validation-email').classList.remove("d-none")
    }
    else if(data.telephone === "" || data.telephone == null){
      document.querySelector('.validation-email').classList.add("d-none")
      document.querySelector('.validation-telephone').classList.remove("d-none")
    }
    else if(data.message === "" || data.message == null){
      document.querySelector('.validation-telephone').classList.add("d-none")
      document.querySelector('.validation-message').classList.remove("d-none")
    }
    else{
      document.querySelector('.validation-message').classList.add("d-none")

      const result = await axios.post(`${process.env.REACT_APP_HUBSPOT_API}`,
      {
        "fields": [
            {
                "name": "firstname",
                "value": data.nom
            },
            {
                "name": "city",
                "value": data.email
            },
            {
                "name": "phone",
                "value": data.telephone
            },
            {
                "name": "Message",
                "value": data.message
            }
        ],
        "context": {
            "pageUri": "www.magistone.ma",
            "pageName": "Magistone Contact form"
        },
      }).then(
        resolve=>{
          setdata(prevdata=>{
            return{
              ...prevdata,
              nom:"",
              email:"",
              telephone:"",
              message:""
            }
          })
            document.querySelector(".devis-envoyé").classList.toggle('d-none')
            setTimeout(()=>{
              document.querySelector(".devis-envoyé").classList.toggle('d-none')
              console.log('success')
            },3000)
        },
        rejected=>{
          setdata(prevdata=>{
            return{
              ...prevdata,
              nom:"",
              email:"",
              telephone:"",
              message:""
            }
          })
            document.querySelector(".devis-non-envoyé").classList.toggle('d-none')
            setTimeout(()=>{
              document.querySelector(".devis-non-envoyé").classList.toggle('d-none')
              console.log('no sucess')
            },3000)
        })
    }
    
    

    
  }
  return (
    <section className='first-section '>
      <main className='container d-flex flex-row justify-content-md-between justify-content-center align-items-md-center align-items-center'>
        <article className='d-md-inline d-none'>
          <h2 className='fs-1 fw-bold w-75'>On n'est pas les seuls mais on est les meilleurs </h2>
          <button className='bg-dark text-white py-2 px-4 rounded border-0 mt-2'>
            <a href='#aboutus' className='text-decoration-none text-white'>Voir plus</a>
          </button>
        </article>
        <form className='form-styling py-5 px-4 rounded mt-md-2 mt-0' onSubmit={handlesubmit}>
          <h2 className='text-center text-dark'>Contactez-Nous</h2>
          <div className="alert alert-success text-center d-none devis-envoyé" role="alert">
            Message envoyé
          </div>
          <div className="alert alert-danger text-center d-none devis-non-envoyé" role="alert">
            Message Non envoyé
          </div>

          <div className='d-flex flex-column my-4'>
            <label className='mb-2'>Nom</label>
            <input type="text" 
              name='nom'
              value={data.nom}
              onChange={handlechange}
            />
            <span className='text-danger d-none validation-nom'>Nom non valide</span>
          </div>
          <div className='d-flex flex-column my-4'>
            <label className='mb-2'>Telephone</label>
            <input type="text"
              name='telephone'
              value={data.telephone}
              onChange={handlechange}
            />
            <span className='text-danger d-none validation-telephone'>Telephone non valide</span>
          </div>
          <div className='d-flex flex-column my-4'>
            <label className='mb-2'>Ville</label>
            <input type="text" 
              name='email'
              value={data.email}
              onChange={handlechange}
            />
            <span className='text-danger d-none validation-email'>Email non valide</span>
          </div>
          <div className='d-flex flex-column my-4'>
            <label className='mb-2'>Message</label>
            <textarea type="text" 
              name='message'
              value={data.message}
              onChange={handlechange}
            />
            <span className='text-danger d-none validation-message'>Message non valide</span>
          </div>
          <div className='d-flex justify-content-center align-items-center'>
            <button className='py-2 px-5 rounded bg-dark text-white form-control shadow-none demande-devis-bnt-animation'>Demandez un Devis</button>
          </div>
        </form>

      </main>
    </section>
  )
}

export default FirstSection