import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay,FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import Partenaire1 from '../assets/6 CERTIFICATIONS-01.png'
import Partenaire2 from '../assets/6 CERTIFICATIONS-02.png'
import Partenaire3 from '../assets/6 CERTIFICATIONS-03.png'
import Partenaire4 from '../assets/6 CERTIFICATIONS-04.png'
import Partenaire5 from '../assets/6 CERTIFICATIONS-05.png'
import Partenaire6 from '../assets/6 CERTIFICATIONS-06.png'


const Certif = () => {
  return (
    <section className='my-5 realisation-styling'>
      <main className='container d-flex flex-column justify-content-center align-items-center'>
        <h2 className='text-center mb-3'>Nos Certifications</h2>
      </main>
      <Swiper
        slidesPerView={4}
        spaceBetween={30}
        loop={true}
        modules={[Autoplay,FreeMode]}
        autoplay={{delay:500}}
        freeMode={true}
        className="mySwiper mt-4 partenaire-swiper"
      >
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center align-items-center rounded partenaire-icon'>
                <img src={Partenaire1} className="img-fluid"/>
            </div>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
          <div className='d-flex justify-content-center align-items-center rounded partenaire-icon'>
                <img src={Partenaire2} className="img-fluid"/>
            </div>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <div className='d-flex justify-content-center align-items-center rounded partenaire-icon'>
              <img src={Partenaire3} className="img-fluid"/>
            </div>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <div className='d-flex justify-content-center align-items-center rounded partenaire-icon'>
          <img src={Partenaire4} className="img-fluid"/>
        </div>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <div className='d-flex justify-content-center align-items-center rounded partenaire-icon'>
          <img src={Partenaire5} className="img-fluid"/>
        </div>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
        <div className='d-flex justify-content-center align-items-center rounded partenaire-icon'>
          <img src={Partenaire6} className="img-fluid"/>
        </div>
        </SwiperSlide>

        
      </Swiper>
    </section>
    )
}

export default Certif