import React from 'react'

const ContactezNous = () => {
  return (
    <section className='Contactez-nous-styling py-5'>
      <article className='container'>
        <div className='row justify-content-center align-items-center'>
          <div className='col-lg-8 col-12'>
            <h2 className='text-white text-lg-start text-center'>Obtenez un design d'intérieur incroyable dès maintenant !</h2>
            <p className='text-white-50 text-lg-start text-center'>À chaque étape, nous pourrions superviser votre projet & contrôler tous les détails.</p>
          </div>
          <div className='col-lg-4 col-12 d-flex justify-content-center align-items-center'>
            <button className='bg-success border-0 py-3 px-5 rounded'>
              <a href='http://wa.me/212666587321' className='text-decoration-none text-white text-nowrap'>Contactez Nous via whatsapp</a>
            </button>
          </div>
        </div>
      </article>
    </section>
  )
}

export default ContactezNous