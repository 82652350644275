import React from 'react'

export const SecondSection = () => {
  return (
    <section className='container second-section'>
        <main className='d-flex flex-column justify-content-center align-items-center'>
            <h2 className='text-center fw-semibold'>MagiStone réalise les rêves d’une vie.</h2>
            <p className='text-center mt-3'>Vous êtes unique, vos projets sont uniques. Que vous soyez Architecte, promoteur, marchand de biens ou professionnel du bâtiment, votre interlocuteur privilégié MagiStone vous accompagne au fil du temps pour concevoir à vos côtés votre projet de design ou d’aménagement d’intérieur. Vous bénéficierez d’un suivi personnalisé grâce à notre bureau d’études, et ce, jusqu’à la livraison et l’installation de votre projet.</p>
        </main>
    </section>
  )
}
export default SecondSection;
