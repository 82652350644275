import React from 'react';
import Home from './pages/Home';
import Error from './pages/Error';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const LazyLoading = React.lazy(()=>import('./pages/Home'))

function App() {
  return (
    <>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='*' element={<Error/>}></Route>
      </Routes>
    </BrowserRouter>
    </>
  );
}

export default App;
