import React from 'react'
import Header from '../layout/shared/Header'
import FirstSection from '../components/FirstSection'
import HomeImg from '../components/HomeImg'
import SecondSection from '../components/SecondSection'
import ThirdSection from '../components/ThirdSection'
import Footer from '../layout/shared/Footer'
import FirstSlider from '../components/FirstSlider'
import Partenaire from '../components/Partenaire'
import LastForm from '../components/LastForm'
import Whatsapp from '../components/Whatsapp'
import Buttonwtsp from '../components/Buttonwtsp'
import ContactezNous from '../components/ContactezNous'
import Aboutus from '../components/Aboutus'
import Certif from '../components/Certif'
const Home = () => {
  return (
    <>
        <Header/>
        <HomeImg/>
        <FirstSection/>
        <Aboutus/>
        <FirstSlider/>
        <ContactezNous/>
        <SecondSection/>
        <ThirdSection/>
        <Partenaire/>
        <ContactezNous/>
        <Certif/>
        <LastForm/>
        <Footer/>
        <Whatsapp/>
    </>
  )
}

export default Home