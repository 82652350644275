import React from 'react'
import whatsapp from '../assets/whatsapp.png'
const Whatsapp = () => {
  return (
    <section className='whatsap-iconn'>
        <a href='http://wa.me/212666587321'>
            <img src={whatsapp} className='img-fluid' alt='magistone whatsapp'/>
        </a>
    </section>
  )
}

export default Whatsapp