import React from 'react'
import Logo from '../../assets/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook,faInstagram,faLinkedin,faTiktok} from '@fortawesome/free-brands-svg-icons'
const Footer = () => {
  return (
    <footer className='mt-5'>
      <div className='container'>
        <div className='row'>
          <main className='col-md-6 col-12  mt-md-0 mt-2'>
            <h3 className='text-white text-md-start text-center'>Contactez-Nous</h3>
            <nav className='d-flex flex-column text-md-start text-center '>
              <a href='https://goo.gl/maps/UWUmM7rcYCcxPruU8' className='my-2 text-white text-decoration-none'>Adresse: Route el jadida. km 16. magasin 2 Ouled azzouz. dar bouazza - casablanca Casablanca, Maroc</a>
              <a href='tel:0666587321' className='mb-2 text-white text-decoration-none'>Tel: +212 666-587321</a>
              <a href='mailto:agencementfacility@gmail.com' className='text-white text-decoration-none'>Email: agencementfacility@gmail.com</a>
            </nav>
          </main>
            <nav className='col-md-6 col-12 d-flex justify-content-md-end justify-content-center align-items-end mt-4'>
              <a href='https://www.facebook.com/magistonemaroc' className='fs-3 text-white text-decoration-none'><FontAwesomeIcon icon={faFacebook}/></a>
              <a href='https://www.instagram.com/magistonemaroc/' className='ms-4 fs-3 text-white text-decoration-none'><FontAwesomeIcon icon={faInstagram}/></a>
              <a href='https://www.linkedin.com/company/magi-stone/' className='ms-4 fs-3 text-white text-decoration-none'><FontAwesomeIcon icon={faLinkedin}/></a>
              <a href='https://www.tiktok.com/@magistone.ma' className='ms-4 fs-3 text-white text-decoration-none'><FontAwesomeIcon icon={faTiktok}/></a>
            </nav>
        </div>
      </div>
    </footer>
  )
}

export default Footer