import React from 'react'

const Googlemap = () => {
    
  return (
    <iframe className='googlmap-styling rounded mt-md-0 mt-4' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14102.232820726585!2d-7.7370555457661006!3d33.48358211296322!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62be2225ec5ed%3A0x457ac5647893d9ed!2sMagistone!5e0!3m2!1sfr!2sma!4v1664379735727!5m2!1sfr!2sma">

    </iframe>
  )
}

export default Googlemap