import React from 'react'
import Logo from "../../assets/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhoneVolume} from '@fortawesome/free-solid-svg-icons'
import {faFacebook,faInstagram,faLinkedin,faTiktok} from '@fortawesome/free-brands-svg-icons'
import {Link} from 'react-router-dom'


const Header = () => {
  return (
    <header className='header-styling py-2'>
      <div className='container'>
      <div className='row align-items-center'>
          <Link to='/' className='col-lg-4 col-6'>
              <img src={Logo} className='img-fluid logo-navbar' alt='Magi Stone Logo'/>
          </Link>
          <nav className='col-lg-8 col-6 d-flex justify-content-end align-items-center header-icons'>
              <a href='https://www.facebook.com/magistonemaroc' className='me-3 d-lg-inline d-none'><FontAwesomeIcon icon={faFacebook}/></a>
              <a href='https://www.instagram.com/magistonemaroc/' className='me-3 d-lg-inline d-none text-danger'><FontAwesomeIcon icon={faInstagram}/></a>
              <a href='https://www.linkedin.com/company/magi-stone/' className='me-3 d-lg-inline d-none'><FontAwesomeIcon icon={faLinkedin}/></a>
              <a href='https://www.tiktok.com/@magistone.ma' className='me-3 d-lg-inline d-none text-dark'><FontAwesomeIcon icon={faTiktok}/></a>
              <nav className='d-flex flex-row justify-content-center align-items-center ms-5'>
                <a href='tel:0600000000' className='me-3 text-dark fs-1 text-decoration-none fw-semibold '>
                  <FontAwesomeIcon icon={faPhoneVolume} className="header-phone"/>
                </a>
                <a href='tel:0666587321' className='me-3 text-dark fs-6 text-decoration-none fw-semibold text-nowrap'>0666-587321</a>
              </nav>
          </nav>
      </div>
      </div>
    </header>
  )
}

export default Header