import React from 'react'
import FirstSectionImg from '../assets/first-section-img.jpg'

const HomeImg = () => {
  return (
    <div className='d-md-none d-inline phone-img-f-s'>
          <article className='d-md-none d-flex flex-column justify-content-center align-items-center py-5 mb-5'>
          <h2 className='fs-1 fw-bold text-center'>On n'est pas les seuls mais on est les meilleurs </h2>
          <button className='bg-dark text-white py-2 px-4 rounded border-0 mt-2'>
            <a href='#aboutus' className='text-decoration-none text-white'>Voir plus</a>
          </button>
        </article>
    </div>
  )
}

export default HomeImg