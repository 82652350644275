import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faRoute,faScroll,faPersonCircleCheck,faClock} from '@fortawesome/free-solid-svg-icons'
const ThirdSection = () => {
  return (
    <section className='px-5 mt-5'>
      <div className='row'>
        <div className='col-lg-3 col-md-6 col-12 d-flex flex-column px-4 third-section-card mb-lg-0 mb-4'>
            <div className='d-flex justify-content-lg-center justify-content-start'>
              <FontAwesomeIcon icon={faRoute} className='fs-1 text-black-50'/>
            </div>
            <h3 className='mt-lg-5 mt-4 mb-2 fw-semibold'>Proximité</h3>
            <p>
              De la conception à l'installation nos équipes interviennent partout au Maroc.
            </p>
        </div>
        <div className='col-lg-3 col-md-6 col-12 d-flex flex-column px-4 third-section-card mb-lg-0 mb-4'>
          <div className='d-flex justify-content-lg-center justify-content-start'>
              <FontAwesomeIcon icon={faScroll} className='fs-1 text-black-50'/>
          </div>
            <h3 className='mt-lg-5 mt-4 mb-2 fw-semibold'>Garantie</h3>
            <p>
              Magi Stone s'engage à livrer l'ensemble de ses projets clés en mains sans imperfection et aux normes d'excellence du marché. Quartz, Granite, Ceramique - Silestone®, Compac®, Caesarsone®, Laminam, Dekton, Nolithe .... nos matériaux sont certifiés.
            </p>
        </div>
        <div className='col-lg-3 col-md-6 col-12 d-flex flex-column px-4 third-section-card mb-lg-0 mb-4 before-last-card'>
          <div className='d-flex justify-content-lg-center justify-content-start'>
            <FontAwesomeIcon icon={faPersonCircleCheck} className='fs-1 text-black-50'/>
          </div>
            <h3 className='mt-lg-5 mt-4 mb-2 fw-semibold'>Conseils</h3>
            <p>
            Travailler avec MagiStone c'est la garantie d'avoir des interlocuteurs qualifiés qui sauront répondre à vos questions techniques et esthétiques, et vous apporter des solutions adaptées.
            </p>
        </div>
        <div className='col-lg-3 col-md-6 col-12 d-flex flex-column px-4 third-section-card mb-lg-0 mb-4 last-card'>
          <div className='d-flex justify-content-lg-center justify-content-start'>
            <FontAwesomeIcon icon={faClock} className='fs-1 text-black-50'/>
          </div>
            <h3 className='mt-lg-5 mt-4 mb-2 fw-semibold'>Délais</h3>
            <p>
              Nos délais sont garantis dès réception de votre commande et validation des stocks par nos ateliers.
            </p>
        </div>
      </div>
    </section>
  )
}

export default ThirdSection