import React,{useState} from 'react'
import axios from 'axios'
import Googlemap from '../layout/api/Googlemap'
const LastForm = () => {
  const [data,setdata] = useState(
    {
      nom:"",
      email:"",
      telephone:"",
      message:""
    }
  )

  const handlechange = (e)=>{
    setdata(
      prevdata =>{
        return{
          ...prevdata,[e.target.name]:e.target.value
        }
      }
    )
  }
  
  const handlesubmit = async (e)=>{
    e.preventDefault()
    
    if(data.nom === "" || data.nom==null){
      document.querySelector('.form-2-validation-nom').classList.remove("d-none")
    }
    else if(data.email === "" || data.email == null){
      document.querySelector('.form-2-validation-nom').classList.add("d-none")
      document.querySelector('.form-2-validation-email').classList.remove("d-none")
    }
    else if(data.telephone === "" || data.telephone == null){
      document.querySelector('.form-2-validation-email').classList.add("d-none")
      document.querySelector('.form-2-validation-telephone').classList.remove("d-none")
    }
    else if(data.message === "" || data.message == null){
      document.querySelector('.form-2-validation-telephone').classList.add("d-none")
      document.querySelector('.form-2-validation-message').classList.remove("d-none")
    }
    else{
      document.querySelector('.form-2-validation-message').classList.add("d-none")

      const result = await axios.post(`${process.env.REACT_APP_HUBSPOT_API}`,
      {
        "fields": [
            {
                "name": "firstname",
                "value": data.nom
            },
            {
                "name": "city",
                "value": data.email
            },
            {
                "name": "phone",
                "value": data.telephone
            },
            {
                "name": "Message",
                "value": data.message
            }
        ],
        "context": {
            "pageUri": "www.test.com",
            "pageName": "test page"
        },
      }).then(
        resolve=>{
          setdata(prevdata=>{
            return{
              ...prevdata,
              nom:"",
              email:"",
              telephone:"",
              message:""
            }
          })
            document.querySelector(".form-2-devis-envoyé").classList.toggle('d-none')
            setTimeout(()=>{
              document.querySelector(".form-2-devis-envoyé").classList.toggle('d-none')
              console.log('success')
            },3000)
        },
        rejected=>{
          setdata(prevdata=>{
            return{
              ...prevdata,
              nom:"",
              email:"",
              telephone:"",
              message:""
            }
          })
            document.querySelector(".form-2-devis-non-envoyé").classList.toggle('d-none')
            setTimeout(()=>{
              document.querySelector(".form-2-devis-non-envoyé").classList.toggle('d-none')
              console.log('no sucess')
            },3000)
        })
    } 
  }
  return (
    <section className='container mt-5'>
      <div className='row justify-content-md-between justify-content-center '>
        <form className='form-styling py-5 px-4 rounded mt-md-2 mt-0 col-lg-4 col-md-6 col-12' onSubmit={handlesubmit}>
            <h2 className='text-center text-dark'>Contactez-Nous</h2>
            <div className="alert alert-success text-center d-none form-2-devis-envoyé" role="alert">
              Message envoyé
            </div>
            <div className="alert alert-danger text-center d-none form-2-devis-non-envoyé" role="alert">
              Message Non envoyé
            </div>

            <div className='d-flex flex-column my-4'>
              <label className='mb-2'>Nom</label>
              <input type="text" 
                name='nom'
                value={data.nom}
                onChange={handlechange}
              />
              <span className='text-danger d-none form-2-validation-nom'>Nom non valide</span>
            </div>
            <div className='d-flex flex-column my-4'>
              <label className='mb-2'>Telephone</label>
              <input type="text" 
                name='telephone'
                value={data.telephone}
                onChange={handlechange}
              />
              <span className='text-danger d-none form-2-validation-telephone'>Telephone non valide</span>
            </div>
            <div className='d-flex flex-column my-4'>
              <label className='mb-2'>Ville</label>
              <input type="text"
                name='email'
                value={data.email}
                onChange={handlechange}
              />
              <span className='text-danger d-none form-2-validation-email'>Email non valide</span>
            </div>
            <div className='d-flex flex-column my-4'>
              <label className='mb-2'>Message</label>
              <textarea type="text"
                name='message'
                value={data.message}
                onChange={handlechange}
              />
              <span className='text-danger d-none form-2-validation-message'>Message non valide</span>
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button className='py-2 px-5 rounded bg-dark text-white form-control shadow-none demande-devis-bnt-animation'>Demandez un Devis</button>
            </div>
        </form>
        <div className='col-lg-8 col-md-6 col-12'>
          <Googlemap/>
        </div>
      </div>
      </section>
  )
}

export default LastForm