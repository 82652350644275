import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

import Img1 from "../assets/img1.jpeg"
import Img2 from "../assets/img2.jpeg"
import Img3 from "../assets/img3.jpeg"
import Img4 from "../assets/img4.jpeg"
import Img5 from "../assets/img5.jpeg"
import Img6 from "../assets/img6.jpeg"
import Img7 from "../assets/img7.jpeg"
import Img8 from "../assets/img8.jpeg"
import Img9 from "../assets/img9.jpeg"


const FirstSlider = () => {
  return (
    <section className='mt-5 realisation-styling'>
      <main className='container d-flex flex-column justify-content-center align-items-center'>
        <h2 className='text-center'>Nos Réalisations</h2>
      </main>
      <Swiper
        slidesPerView={1}
        spaceBetween={0}
        loop={true}
        navigation={true}
        
        modules={[ Navigation]}
        className="mySwiper first-swiper mt-5"
      >
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img1} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img2} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img3} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img4} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img5} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img6} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img7} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img8} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
        <SwiperSlide className='d-flex justify-content-center align-items-center'>
            <img src={Img9} className='img-fluid w-100' alt=''/>
        </SwiperSlide>
      </Swiper>
    </section>
    )
}

export default FirstSlider