import React from 'react'
import Header from '../layout/shared/Header'
import Footer from '../layout/shared/Footer'
import {Link} from 'react-router-dom'
const Error = () => {
  return (
    <>
        <Header/>
            <main className='container error-404 d-flex flex-column justify-content-center align-items-center'>
                <h1 className='text-center'>Error 404</h1>
                <Link to='/' className='py-2 px-4 rounded mt-5'>Back Home</Link>
            </main>
        <Footer/>
    </>
  )
}

export default Error